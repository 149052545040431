html {
	font-size: 14px;
}

body {
	margin: 0;
	padding: 0;
	font-family: "Segoe UI", sans-serif;
}

form {
	margin-top: 30px;
	font-size: 14px;
}

form p,
form .select-wrapper input.select-dropdown {
	color: grey;
	border-color: grey;
	font-size: 1rem;
	height: 2rem;
	line-height: 2rem;
	margin: 0;
}
form .select-wrapper .dropdown-content li {
	padding: 0;
	min-height: 30px;
}

form .select-wrapper .dropdown-content li > a, 
form .select-wrapper .dropdown-content li > span {
	font-size: 1rem;
	line-height: 1rem;
	padding: 8px 16px;
}

form .select-wrapper .select-dropdown li img {
	height: 1rem;
	width: 1rem;
}

form button, 
form h5 {
	margin: 1rem 0;
}

form .input-field input {
	padding: 0 5px;
}

form .divider {
	margin: 2rem 0;
}

.form-select-field {
	display: flex;
}

.form-select-field .label {
	padding-right: 0;
}

.form-select-field .marker {
	width: 5px;
	padding: 0;
}

.header-brand-logo {
	display: flex;
	align-items: center;
	position: absolute;
	color: #fff;
	font-size: 2.1rem;
}

.header-brand-logo img {
	display: none;
	margin-right: 1rem;
	margin-bottom: 0.5rem;
}

.initials-ico {
	box-shadow: 0 0 3px #c2185b;
}

.project-card-title-container {
	margin-top: 1rem;
	padding: 0.5rem;
	font-weight: bold;
	color: #616161;
	border-bottom: 1px #616161 solid;
	border-top: 1px #616161 solid;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.project-card-title-container .ctr {
	font-size: 1rem;
	opacity: .3;
}

.project-card-title-container .title {
	font-size: 1rem
}

.create-case-form textarea {
	min-height: 10rem;
}

.form-property-label {
	overflow: hidden;
	text-overflow: ellipsis;
}

.preloader-centering {
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -50px 0px 0px -50px;
}

.user-confirmation-list {
	border: none;
}

.user-confirmation-list .secondary-content * {
	margin: 0 10px;
	cursor: pointer;
}