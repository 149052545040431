html {
	font-size: 14px;
}

body {
	margin: 0;
	padding: 0;
	font-family: "Segoe UI", sans-serif;
}

form {
	margin-top: 30px;
	font-size: 14px;
}

form p,
form .select-wrapper input.select-dropdown {
	color: grey;
	border-color: grey;
	font-size: 1rem;
	height: 2rem;
	line-height: 2rem;
	margin: 0;
}
form .select-wrapper .dropdown-content li {
	padding: 0;
	min-height: 30px;
}

form .select-wrapper .dropdown-content li > a, 
form .select-wrapper .dropdown-content li > span {
	font-size: 1rem;
	line-height: 1rem;
	padding: 8px 16px;
}

form .select-wrapper .select-dropdown li img {
	height: 1rem;
	width: 1rem;
}

form button, 
form h5 {
	margin: 1rem 0;
}

form .input-field input {
	padding: 0 5px;
}

form .divider {
	margin: 2rem 0;
}

.form-select-field {
	display: flex;
}

.form-select-field .label {
	padding-right: 0;
}

.form-select-field .marker {
	width: 5px;
	padding: 0;
}

.header-brand-logo {
	display: flex;
	align-items: center;
	position: absolute;
	color: #fff;
	font-size: 2.1rem;
}

.header-brand-logo img {
	display: none;
	margin-right: 1rem;
	margin-bottom: 0.5rem;
}

.initials-ico {
	box-shadow: 0 0 3px #c2185b;
}

.project-card-title-container {
	margin-top: 1rem;
	padding: 0.5rem;
	font-weight: bold;
	color: #616161;
	border-bottom: 1px #616161 solid;
	border-top: 1px #616161 solid;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.project-card-title-container .ctr {
	font-size: 1rem;
	opacity: .3;
}

.project-card-title-container .title {
	font-size: 1rem
}

.create-case-form textarea {
	min-height: 10rem;
}

.form-property-label {
	overflow: hidden;
	text-overflow: ellipsis;
}

.preloader-centering {
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -50px 0px 0px -50px;
}

.user-confirmation-list {
	border: none;
}

.user-confirmation-list .secondary-content * {
	margin: 0 10px;
	cursor: pointer;
}
/* Data grid table */
.ctr-data-grid {
	margin-top: 1rem;
}

.ctr-data-grid .react-grid-Main {
	outline: none;
}

.ctr-data-grid .react-grid-Grid {
	border: none;
}

.ctr-data-grid .react-grid-Canvas {
	overflow: hidden !important;
	margin-top: 1rem;
}

.ctr-data-grid .react-grid-Header {
	background: none;
	box-shadow: none;
	color: #616161;
	border-bottom: 1px #616161 solid;
  border-top: 1px #616161 solid;
}

.ctr-data-grid .react-grid-HeaderCell,
.ctr-data-grid .react-grid-Cell {
	background: none;
	border-right: none;
	border-bottom: none;
}

.ctr-data-grid .rdg-selected {
  /* border-opacity: transparent; */
  opacity: .2;
}

.ctr-data-grid .drag-handle {
  display: none;
}

/* Context menu */
.react-contextmenu {
  width: 172px;
  height: auto;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  color: #373a3c;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  outline: none;
  opacity: 0;
  pointer-events: none;
	-webkit-transition: opacity 250ms ease !important;
	transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
}

.react-contextmenu-item {
  padding: 3px 20px;
  font-weight: 400;
  line-height: 1.5;
  color: #373a3c;
  text-align: inherit;
  white-space: nowrap;
  background: 0 0;
  border: 0;
  cursor: pointer;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #00897b;
  border-color: #00897b;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  color: #878a8c;
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item--divider {
  margin-bottom: 3px;
  padding: 2px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
}

.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}

.rdg-editor-container input.editor-main {
  box-sizing: border-box;
}
.rdg-editor-container select {
  display: inline-block;
}

.ctr-data-grid .rdg-cell-expand {
  margin-right: 1rem;
  padding-top: 2px;
}

.ctr-data-grid .rdg-cell-expand > span {
  color: #26a69a;
}

.ctr-data-grid .rdg-child-row-action-cross {
  display: none;
}

.ctr-data-grid.sprint-backlog-data-grid .react-grid-Cell:not(.rdg-child-cell) span > div {
  font-weight: 700;
  color: #616161;
}

.ctr-data-grid.product-backlog-data-grid .react-grid-cell-copied {
  background: none !important;
}
